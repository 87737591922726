
  
 div.signinForm{
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #f3f2f2;
  }
  
  div.signinForm h4 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    opacity: 0.85;
  }
  
  div.signinForm label {
    font-size: 12.5px;
    color: #000;
    opacity: 0.8;
    font-weight: 400;
  }
  
  div.signinForm form {
    padding: 40px 30px;
    background: #fefefe;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    width: 300px;
  }
  div.signinForm form h4 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
  div.signinForm form h4 span {
    color: black;
    font-weight: 700;
  }
  div.signinForm form p {
    line-height: 155%;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    opacity: 0.65;
    font-weight: 400;
    max-width: 200px;
    margin-bottom: 40px;
  }
  
  div.signinForm a.discrete {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    border-bottom: solid 1px rgba(0, 0, 0, 0);
    padding-bottom: 4px;
    margin-left: auto;
    font-weight: 300;
    transition: all 0.3s ease;
    margin-top: 40px;
  }
  div.signinForm a.discrete:hover {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  
  div.signinForm button {
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 15px 40px;
    margin-top: 5px;
    background-color: #b08bf8;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    font-weight: 500;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    transition: all 0.3s ease;
    outline: 0;
    cursor: pointer;
  }
  div.signinForm button:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px rgba(182, 157, 230, 0.65);
  }
  div.signinForm button:hover:active {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
  }
  
  div.signinForm input {
    font-size: 16px;
    padding: 20px 0px;
    height: 56px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 280px;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;
  }
  div.signinForm input:focus {
    border-bottom: solid 1px #b69de6;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(182, 157, 230, 0.45);
  }
  
  div.signinForm .floatinglabel {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
  }
  div.signinForm .floatinglabel label {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    opacity: 0;
    transition: all 0.3s ease;
    padding-left: 44px;
  }
  div.signinForm .floatinglabel input {
    width: calc(100% - 44px);
    margin-left: auto;
    display: flex;
  }
  div.signinForm .floatinglabel .icon {
    position: absolute;
    top: 0px;
    left: 0;
    height: 54px;
    width: 54px;
    font-size: 20px;
    color: rgb(65, 65, 65);
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  div.signinForm .floatinglabel input:not(:placeholder-shown) {
    padding: 28px 0px 12px 0px;
  }
  div.signinForm .floatinglabel input:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0.7;
  }
  div.signinForm .floatinglabel input:valid:not(:placeholder-shown) + div.signinForm label + div.signinForm .icon svg {
    opacity: 1;
  }
  div.signinForm .floatinglabel input:valid:not(:placeholder-shown) + div.signinForm label + div.signinForm .icon svg path {
    fill: #b69de6;
  }
  div.signinForm .floatinglabel input:not(:valid):not(:focus) + div.signinForm label + div.signinForm .icon {
    -webkit-animation-name: shake-shake;
            animation-name: shake-shake;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  
  @-webkit-keyframes shake-shake {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  @keyframes shake-shake {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  div.signinForm .session {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
  
  div.signinForm .left {
    width: 220px;
    height: auto;
    min-height: 100%;
    position: relative;
    background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  @media(max-width:630px){
    div.signinForm .left{
      display: none;
    }
    div.signinForm form{
      width: auto;
    }
    div.signinForm form p{
      margin-bottom: 3px;
    }
    div.signinForm button{
      margin-left: 20%;
    }
  }