@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

 .sep2{
     overflow: scroll;
 }
 .row{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
 }
 div.template4BodyCont{
     max-width: 900px;
    
 }
 div.template4Body{
    display: flex;
    flex-flow: column;
    justify-content: center;

    align-items: center;
 }
 
    /* div.template4BodyCont{
        
        font-size: 20px;
    } */
    @media print {

       
        th {
            text-align: inherit !important;
          }
          .table {
            width: 100% !important;
            margin-bottom: 1rem !important;
            color: #212529 !important;
          }
          
          .table th,
          .table td {
            padding: 0.75rem !important;
            vertical-align: top !important;
            border-top: 1px solid #dee2e6 !important;
          }
          
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6 !important;
          }
          
          .table tbody + tbody {
            border-top: 2px solid #dee2e6 !important;
          }
          .table-bordered {
            border: 1px solid #dee2e6 !important;
          }
          
          .table-bordered th,
          .table-bordered td {
            border: 1px solid #dee2e6 !important;
          }
          
          .table-bordered thead th,
          .table-bordered thead td {
            border-bottom-width: 2px !important;
          }
        div.template4Body  .table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05) !important;
          }
          div.template4Body   .table-dark.table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05) !important;
          }
        div.template4Body{
            display: flex;
            flex-flow: column;
            justify-content: center;
            font-size: 15px !important;
            align-items: center;
            margin: 2mm 0mm 2mm 15mm;
         }
        div.template4BodyCont{
            max-width: unset;
           
        }
        div.template4Body h2{
            font-weight:600; 
            margin: 6px 0px 0px 0px;
        }
        
        div.template4Body,div.template4Body h1,div.template4Body h2,div.template4Body h3,div.template4Body ol,div.template4Body ul,div.template4Body div,div.template4Body span,div.template4Body p {
            display: block !important;
            /* width: auto !important; */
            /* float: none !important; */
            /* position: static !important; */
            overflow: visible !important;
        }
        
      }
 
