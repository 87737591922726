div.ldsLoader {
  display: flex;
  position: fixed;
  height: 100vh;
  justify-content: center;
  flex-flow: column;
  color: rgb(50, 50, 50);
  font-size: 20px;
  z-index: 100;
  font-family: 'M PLUS Rounded 1c',sans-serif;
  top:0;
  left: 0;
  bottom: 0;
  font-weight: 600;
  right: 0;
  align-items: center;
  background: #d8d8d8c9;
}

div.ldsLoader .wrapper {
  margin-bottom: 40px;
}

div.ldsLoader ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  -webkit-animation: rot 16s linear infinite;
          animation: rot 16s linear infinite;
}
@-webkit-keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rot {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
div.ldsLoader li {
  width: 20px;
  height: 20px;
  background: #651FFF;
  border-radius: 4px;
  box-shadow: 0 0 1px #fff, 0 0 5px #651FFF, 0 0 10px #651FFF, 0 0 15px #651FFF, 0 0 25px #651FFF, 0 0 55px #651FFF;
  -webkit-animation: scale 0.8s linear alternate infinite;
          animation: scale 0.8s linear alternate infinite;
}
@-webkit-keyframes scale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
@keyframes scale {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
div.ldsLoader li:nth-child(1) {
  z-index: 24;
}
div.ldsLoader li:nth-child(2) {
  z-index: 23;
}
div.ldsLoader li:nth-child(3) {
  z-index: 22;
}
div.ldsLoader li:nth-child(4) {
  z-index: 21;
}
div.ldsLoader li:nth-child(5) {
  z-index: 20;
}
div.ldsLoader li:nth-child(6) {
  z-index: 19;
}
div.ldsLoader li:nth-child(7) {
  z-index: 18;
}
div.ldsLoader li:nth-child(8) {
  z-index: 17;
}
div.ldsLoader li:nth-child(9) {
  z-index: 16;
}
div.ldsLoader li:nth-child(10) {
  z-index: 15;
}
div.ldsLoader li:nth-child(11) {
  z-index: 14;
}
div.ldsLoader li:nth-child(12) {
  z-index: 13;
}
div.ldsLoader li:nth-child(13) {
  z-index: 12;
}
div.ldsLoader li:nth-child(14) {
  z-index: 11;
}
div.ldsLoader li:nth-child(15) {
  z-index: 10;
}
div.ldsLoader li:nth-child(16) {
  z-index: 9;
}
div.ldsLoader li:nth-child(17) {
  z-index: 8;
}
div.ldsLoader li:nth-child(18) {
  z-index: 7;
}
div.ldsLoader li:nth-child(19) {
  z-index: 6;
}
div.ldsLoader li:nth-child(20) {
  z-index: 5;
}
div.ldsLoader li:nth-child(21) {
  z-index: 4;
}
div.ldsLoader li:nth-child(22) {
  z-index: 3;
}
div.ldsLoader li:nth-child(23) {
  z-index: 2;
}
div.ldsLoader li:nth-child(24) {
  z-index: 1;
}
div.ldsLoader li:nth-child(25) {
  z-index: 0;
}
div.ldsLoader li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
div.ldsLoader li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.ldsLoader li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.ldsLoader li:nth-child(24) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
div.ldsLoader li:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
div.ldsLoader li:nth-child(8) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.ldsLoader li:nth-child(14) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.ldsLoader li:nth-child(20) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
div.ldsLoader li:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.ldsLoader li:nth-child(9) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(15) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.ldsLoader li:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.ldsLoader li:nth-child(10) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.ldsLoader li:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
div.ldsLoader li:nth-child(6) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
div.ldsLoader li:nth-child(11) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.ldsLoader li:nth-child(16) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.ldsLoader li:nth-child(21) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.ldsLoader li:nth-child(12) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.ldsLoader li:nth-child(17) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(22) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.ldsLoader li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.ldsLoader li:nth-child(18) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.ldsLoader li:nth-child(23) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.ldsLoader li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.ldsLoader li:nth-child(24) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
div.ldsLoader li:nth-child(25) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
