div.template2Body{
    display: flex;
    flex-flow: column;
    align-items: center;
}
div.template2 {
    font-family: "Adobe Caslon Pro", "Minion Pro", serif;
    font-size: 12pt;
    width:100%;
    max-width: 800px;

}

div.template2 header {
    font-family: "Trajan Pro", serif;
    padding-bottom: 10px;
}

div.template2 header h1 {
    font-size: 20pt;
    letter-spacing: 2pt;
    border-bottom: 1px solid black;
    margin-bottom: 4px;
}

div.template2 header span {
    font-size: 10pt;
    float: right;
}

div.template2 section h2 {
    font-family: "Trajan Pro", serif;
    font-size: 14pt;
}
div.template2 section{
    margin: 20px 0px;
    box-sizing: border-box;
}
div.template2 section p {
    padding-left: 40px;
    box-sizing: border-box;
}

div.template2 section.coverletter {
    margin-top: 40px;
}

div.template2 section.coverletter p {
    margin-left: 0px;
}

div.template2 section ul {
    list-style-type: circle;
}

div.template2 .jobtable {
    display: table;
    width: 100%;
    border-bottom: 1px solid black;
    padding-left: 20px;
    box-sizing: border-box;

}

div.template2 .edtable {
    display: table;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 15px;
    box-sizing: border-box;

}

div.template2 .skillstable {
    display: table;
    width: 100%;
}

div.template2 .table {
    display: table;
    padding-left: 20px;
    box-sizing: border-box;

}

div.template2 .tablerow {
    display: table-row;
}

div.template2 .jobtitle {
    display: table-cell;
    font-style: italic;
}

div.template2 .right {
    display: table-cell;
    text-align: right;
}

div.template2 .cell {
    display: table-cell;
}

div.template2 .onlinecell {
    font-style: italic;
    padding-right: 10px;
    box-sizing: border-box;

}

div.template2 .urlcell {
    display: table-cell;
    letter-spacing: 1px;
}

div.template2 .pagebreak {
    page-break-before: always;
}
