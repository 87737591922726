.resumeList {
    width: 200px;
    margin:10px;
    background: #FDFEFF;
    transition: background .6s ease;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  }
  .resumeList .topIcons i {
    color: #080911;
  }
  .resumeList .topIcons i:nth-of-type(1) {
    float: left;
  }
  .resumeList .topIcons i:nth-of-type(2) {
    float: right;
  }
  .resumeList .topIcons i:nth-of-type(3) {
    float: right;
    padding-right: .8em;
  }
  .resumeList .profile {
    margin-top: 2.2em;
    position: relative;
  }
  .resumeList .profile:after {
    width: 100%;
    height: 1px;
    content: ' ';
    display: block;
    margin-top: 1.3em;
    background: #E9EFF6;
  }
  .resumeList .profile .check {
    position: absolute;
    right: 5em;
    bottom: 12.7em;
  }
  .resumeList .profile .check i {
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .resumeList .profile .thumbnail {
    width: 124px;
    height: 124px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    border-radius: 100%;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .resumeList .profile .name {
    color: #2D354A;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .resumeList .profile .title {
    color: #7C8097;
    font-size: .75em;
    font-weight: 300;
    text-align: center;
    padding-top: .5em;
    padding-bottom: .7em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .resumeList .profile .description {
    color: #080911;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1.3em;
  }
  .resumeList .profile .btn {
    color: #fff;
    width: 130px;
    height: 42px;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    border-radius: 70px;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .resumeList .socialIcons {
    display: flex;
    margin-top: 1.2em;
    justify-content: space-between;
  }
  .resumeList .socialIcons .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .resumeList .socialIcons .icon button {
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 28px;
    line-height: 60px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .resumeList .socialIcons .icon:nth-of-type(1) button {
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .resumeList .socialIcons .icon:nth-of-type(2) button{
    background: linear-gradient(to bottom right, #5E5AEC, #3F9EFC);
  }
  .resumeList .socialIcons .icon:nth-of-type(3) button {
    background: linear-gradient(to bottom right, #6452E9, #639FF9);
  }
  .resumeList .socialIcons .icon h4 {
    color: #080911;
    font-size: 1em;
    margin-top: 1.3em;
    margin-bottom: .2em;
  }
  .resumeList .socialIcons .icon p {
    color: #666B7D;
    font-size: 12px;
  }
  