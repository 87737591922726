div.templateInput label{
    display: block;
    font-weight: 600;
}
div.templateInput input{
    font-family: 'M PLUS Rounded 1c', sans-serif;
  margin:10px;
  box-sizing:border-box;
  border:1px solid rgba(0,0,0,0.05) ;
  padding:10px;
  width:100%;
  border-radius:4px;
  font-size:15px;
  box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.08);
  transition: box-shadow 0.4s ease-in-out;
}
div.templateInput input:focus{
    box-shadow: 0px 3px 20px 6px rgba(0,0,0,0.12);
}