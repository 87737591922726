div.templateEdit{
    display: flex;
    justify-content: space-around;
}
div.templateEdit div.leftHalf{
    width: 40vw;
    padding:40px 20px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
}
div.templateEdit div.leftHalf .sectionList{
    width: 80%;
    text-align: center;
}
div.templateEdit div.rightHalf{
    width: 58vw;
    height: 100vh;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.194);
    padding: 10px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.sticky{
    position: sticky;
    top: -50px;
    text-align: center;
    left:0;
    right: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
}
@media(max-width:700px){
    div.templateEdit{
        flex-flow: column;
    }
    #templateView{
        margin-top: 80px;
    }
    div.templateEdit div.leftHalf{
        width: 100vw;
        height: auto;
        padding-left:0;
        padding-right:0;

    }
    div.templateEdit div.rightHalf{
        padding-left:0;
        height: auto;
        padding-right:0;
        width: 100vw;
    }
}
#templateView{
    padding: 10px;
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    -moz-page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
}
@media print {
    @page {
        margin: 2.2cm 2.2cm 1.8cm;
        
      }
      
    button.printBtn{
        display: none;
    }
}