@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);

       

        html {
            font-size: 62.5%;
            box-sizing: border-box
        }

        .template3Body *,
        .template3Body *:before,
        .template3Body *:after {
            box-sizing: inherit
        }

        .template3Body {
            margin: 0;
            background-color: #fff
        }

        .template3Body img {
            max-width: 100%;
            height: auto
        }

        .template3Body .uCf:before,
        .template3Body .uCf:after {
            content: " ";
            display: table
        }

        .template3Body .uCf:after {
            clear: both
        }

        .template3Body .uHug {
            margin-top: 0 !important
        }

        .template3Body .uPullRight {
            float: right
        }

        .template3Body .uKeyline {
            position: relative
        }

         .template3Body .uKeyline:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #ddd
        }

        .template3Body .uLink {
            text-decoration: none;
            position: relative;
            display: inline-block
        }

        .template3Body .uLink:before {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: .2em;
            width: 100%;
            height: 2px;
            background-color: #96ddcf
        }

        .template3Body .uLink:visited:before {
            background-color: #ddd
        }

        .template3Body .uLink:hover:before {
            background-color: #fa8072
        }

        .template3Body .uShadow {
            text-shadow: 4px 4px 0 #6fd1bd;
            transition: text-shadow .2s ease-in-out
        }

        .template3Body .uShadow:hover {
            text-shadow: -4px -4px 0 salmon
        }

        @media (max-width:767px) {
            .template3Body    .uHidden {
                display: none !important
            }
        }

        @media (max-width:543px) {
            .template3Body   .uHidden {
                display: none !important
            }
        }

        .template3Body {
            color: #333;
            font-size: 1.6rem;
            font-family: Lato, Helvetica, Arial, sans-serif;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: .008em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        .template3Body h1,
        .template3Body h2,
        .template3Body h3,
        .template3Body h4,
        .template3Body h5,
        .template3Body h6 {
            color: #111;
            line-height: 1.2;
            font-weight: 900
        }

        .template3Body h1,
        .template3Body h2,
        .template3Body h3,
        .template3Body h4,
        .template3Body h5,
        .template3Body h6,
        .template3Body ul,
        .template3Body p {
            margin-top: 1.6rem;
            margin-bottom: 0
        }

        .template3Body h3,
        .template3Body .h3 {
            font-size: 2rem;
            margin-top: 3.2rem
        }

        .template3Body a {
            color: #333
        }

        .template3Body ul {
            padding-left: 1.8rem
        }

        .template3Body .Title {
            font-size: 50px;
            font-size: 8vw;
            font-family: Lato, 'Open Sans', Helvetica, Arial;
            font-weight: 900;
            line-height: 1.1;
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
            display: inline-block
        }

        @media (max-width:525px) {
            .template3Body  .Title {
                font-size: 42px
            }
        }

        @media (min-width:937.5px) {
            .template3Body .Title {
                font-size: 75px
            }
        }

        .template3Body .TitleSub {
            font-size: .34em;
            letter-spacing: .16em;
            font-weight: 400;
            text-transform: none;
            display: block;
            margin-top: 1.2rem;
            padding-top: 1.6rem;
            border-top: .45em solid
        }

        .template3Body .lWrapper {
            width: 80%;
            max-width: 90rem;
            margin-left: auto;
            margin-right: auto
        }

        @media (max-width:767px) {
            .template3Body  .lWrapper--reset {
                width: 100%
            }
        }

        .template3Body .lHeader {
            margin-top: 6.4rem;
            margin-bottom: 3.2rem
        }

        .template3Body .lHeaderCol {
            margin-top: 3.2rem;
            margin-bottom: 3.2rem
        }

        @media (min-width:544px) {
            .template3Body  .lHeader {
                display: flex;
                justify-content: space-between;
                align-items: flex-end
            }

            .template3Body  .lHeaderCol:last-child:not(:first-child) {
                text-align: right
            }
        }

        .template3Body .lSection {
            margin-top: 6.4rem
        }

        .template3Body .lSection+.template3Body .lSection {
            position: relative;
            border-top: 1px solid #ddd
        }

        .template3Body .lSection+.template3Body .lSection:before {
            content: "";
            font-size: 50px;
            font-size: 8vw;
            position: absolute;
            top: -.1rem;
            border-top: .153em solid;
            width: 4rem
        }

        @media (max-width:525px) {
            .template3Body .lSection+.template3Body .lSection:before {
                font-size: 42px
            }
        }

        @media (min-width:937.5px) {
            .template3Body .lSection+.template3Body .lSection:before {
                font-size: 75px
            }
        }

        .template3Body .lSectionTitle,
        .template3Body  .lSectionContent {
            margin-top: 3.2rem
        }

        .template3Body .lSectionContent>:first-child,
        .template3Body .lSectionContent>:first-child>h3 {
            margin-top: 0
        }

        .template3Body .lSectionContent>p:first-child,
        .template3Body .lSectionContent>ul:first-child {
            margin-top: .3rem
        }

        .template3Body .lSectionGroup {
            display: inline
        }

        @media (min-width:768px) {
            .template3Body  .lSection {
                display: flex
            }

            .template3Body .lSection:before {
                display: none
            }

            .template3Body .lSectionTitle {
                flex: 0 0 24%
            }
        }

        .template3Body .lFooter {
            font-size: 1.4rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 12.8rem -.8rem 3.2rem;
            padding-top: 3.2rem
        }

        .template3Body .lFooter a {
            text-decoration: none;
            display: inline-block
        }

        .template3Body .lFooter a:not(:last-child) {
            margin-right: 1.6rem
        }

        .template3Body .lFooter a:hover {
            color: #111
        }

        .template3Body .lFooterCol {
            padding-left: .8rem;
            padding-right: .8rem
        }

       

        

        .template3Body .Tag {
            color: #111;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, .4);
            font-weight: 700;
            line-height: 1;
            position: relative;
            display: inline-block;
            padding-left: .8rem;
            padding-right: .8rem
        }

        .template3Body .Tag:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            height: 1em;
            bottom: 0;
            margin: auto;
            border-radius: .2rem;
            background-color: rgba(111, 209, 189, .5);
            transition: all .8s .4s ease-in-out
        }

        .template3Body .Tag:hover:before {
            transform: scale(1.05, 2.4);
            background-color: #6fd1bd;
            transition-delay: 0s;
            transition-duration: .2s
        }

         @media print {
            .template3Body  {
                font-size:15px;
            }

            .template3Body .lWrapper {
                width: 100%;
                max-width: none
            }

           
            .template3Body .uHidden\@print {
                display: none
            }

            .template3Body  .lHeader,
            .template3Body .lHeaderCol {
                margin-top: 0
            }

            .template3Body  .lFooter,
            .template3Body  .lSection {
                margin-top: 3.2rem
            }

            .template3Body  h3,
          .template3Body  .h3,
          .template3Body  .lSectionTitle,
          .template3Body  .lSectionContent {
                margin-top: 1.8rem
            }

            .template3Body .Title {
                font-size: 4.5rem
            }

            .template3Body .lHeader {
                display: flex;
                justify-content: space-between;
                align-items: flex-end
            }

            .template3Body  .lHeaderCol:last-child:not(:first-child) {
                text-align: right
            }

            .template3Body  .lSection {
                position: relative;
                padding-top: 5px;
                border-top: 1px solid #ddd
            }

            .template3Body .lSection:before {
                display: none
            }

            .template3Body .lSection:after {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                border-top: 5px solid;
                width: 4rem
            }

            .template3Body  .lSectionGroup {
                display: block;
                page-break-inside: avoid
            }

            .template3Body .Tag {
                font-weight: 900;
                display: inline;
                padding-left: 0;
                padding-right: 0
            }

           .template3Body *,
           .template3Body *:before,
           .template3Body *:after,
           .template3Body p:first-letter,
           .template3Body div:first-letter,
           .template3Body blockquote:first-letter,
           .template3Body li:first-letter,
           .template3Body p:first-line,
           .template3Body div:first-line,
           .template3Body blockquote:first-line,
           .template3Body li:first-line {
                background: transparent !important;
                color: #000 !important;
                box-shadow: none !important;
                text-shadow: none !important
            }

          .template3Body  a,
          .template3Body  a:visited {
                text-decoration: underline
            }

           .template3Body p,
           .template3Body h2,
           .template3Body h3,
           .template3Body li {
                orphans: 3;
                widows: 3
            }

           .template3Body h2,
           .template3Body h3 {
                page-break-after: avoid
            }

           .template3Body .Contact:after {
                content: "(206) 960-0961";
                padding-left: 1.6rem
            }
        }
        @page {
            margin: 2.2cm 2.2cm 1.8cm
        } 