@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App__App__1o-Fp {
    position: relative;
}
*{
    margin: 0;
    box-sizing: border-box;
}


  
 div.SignIn__signinForm__1JO_F{
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background: #f3f2f2;
  }
  
  div.SignIn__signinForm__1JO_F h4 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    opacity: 0.85;
  }
  
  div.SignIn__signinForm__1JO_F label {
    font-size: 12.5px;
    color: #000;
    opacity: 0.8;
    font-weight: 400;
  }
  
  div.SignIn__signinForm__1JO_F form {
    padding: 40px 30px;
    background: #fefefe;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-bottom: 20px;
    width: 300px;
  }
  div.SignIn__signinForm__1JO_F form h4 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
  div.SignIn__signinForm__1JO_F form h4 span {
    color: black;
    font-weight: 700;
  }
  div.SignIn__signinForm__1JO_F form p {
    line-height: 155%;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    opacity: 0.65;
    font-weight: 400;
    max-width: 200px;
    margin-bottom: 40px;
  }
  
  div.SignIn__signinForm__1JO_F a.SignIn__discrete__3t3me {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    border-bottom: solid 1px rgba(0, 0, 0, 0);
    padding-bottom: 4px;
    margin-left: auto;
    font-weight: 300;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 40px;
  }
  div.SignIn__signinForm__1JO_F a.SignIn__discrete__3t3me:hover {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  
  div.SignIn__signinForm__1JO_F button {
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 15px 40px;
    margin-top: 5px;
    background-color: #b08bf8;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    font-weight: 500;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    outline: 0;
    cursor: pointer;
  }
  div.SignIn__signinForm__1JO_F button:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px rgba(182, 157, 230, 0.65);
  }
  div.SignIn__signinForm__1JO_F button:hover:active {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
  }
  
  div.SignIn__signinForm__1JO_F input {
    font-size: 16px;
    padding: 20px 0px;
    height: 56px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 280px;
    box-sizing: border-box;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;
  }
  div.SignIn__signinForm__1JO_F input:focus {
    border-bottom: solid 1px #b69de6;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(182, 157, 230, 0.45);
  }
  
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l label {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-left: 44px;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input {
    width: calc(100% - 44px);
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l .SignIn__icon__3wd_J {
    position: absolute;
    top: 0px;
    left: 0;
    height: 54px;
    width: 54px;
    font-size: 20px;
    color: rgb(65, 65, 65);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
  }
  
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input:not(:placeholder-shown) {
    padding: 28px 0px 12px 0px;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0.7;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input:valid:not(:placeholder-shown) + div.SignIn__signinForm__1JO_F label + div.SignIn__signinForm__1JO_F .SignIn__icon__3wd_J svg {
    opacity: 1;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input:valid:not(:placeholder-shown) + div.SignIn__signinForm__1JO_F label + div.SignIn__signinForm__1JO_F .SignIn__icon__3wd_J svg path {
    fill: #b69de6;
  }
  div.SignIn__signinForm__1JO_F .SignIn__floatinglabel__1ez6l input:not(:valid):not(:focus) + div.SignIn__signinForm__1JO_F label + div.SignIn__signinForm__1JO_F .SignIn__icon__3wd_J {
    -webkit-animation-name: SignIn__shake-shake__VQqqo;
            animation-name: SignIn__shake-shake__VQqqo;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  
  @-webkit-keyframes SignIn__shake-shake__VQqqo {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  @keyframes SignIn__shake-shake__VQqqo {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  div.SignIn__signinForm__1JO_F .SignIn__session__3Jbyy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
  
  div.SignIn__signinForm__1JO_F .SignIn__left__o1j-8 {
    width: 220px;
    height: auto;
    min-height: 100%;
    position: relative;
    background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  @media(max-width:630px){
    div.SignIn__signinForm__1JO_F .SignIn__left__o1j-8{
      display: none;
    }
    div.SignIn__signinForm__1JO_F form{
      width: auto;
    }
    div.SignIn__signinForm__1JO_F form p{
      margin-bottom: 3px;
    }
    div.SignIn__signinForm__1JO_F button{
      margin-left: 20%;
    }
  }
div.Loader__ldsLoader__32ymL {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: column;
          flex-flow: column;
  color: rgb(50, 50, 50);
  font-size: 20px;
  z-index: 100;
  font-family: 'M PLUS Rounded 1c',sans-serif;
  top:0;
  left: 0;
  bottom: 0;
  font-weight: 600;
  right: 0;
  -webkit-align-items: center;
          align-items: center;
  background: #d8d8d8c9;
}

div.Loader__ldsLoader__32ymL .Loader__wrapper__2ESBt {
  margin-bottom: 40px;
}

div.Loader__ldsLoader__32ymL ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  -webkit-animation: Loader__rot__PJbUp 16s linear infinite;
          animation: Loader__rot__PJbUp 16s linear infinite;
}
@-webkit-keyframes Loader__rot__PJbUp {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader__rot__PJbUp {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
div.Loader__ldsLoader__32ymL li {
  width: 20px;
  height: 20px;
  background: #651FFF;
  border-radius: 4px;
  box-shadow: 0 0 1px #fff, 0 0 5px #651FFF, 0 0 10px #651FFF, 0 0 15px #651FFF, 0 0 25px #651FFF, 0 0 55px #651FFF;
  -webkit-animation: Loader__scale__HPuu3 0.8s linear alternate infinite;
          animation: Loader__scale__HPuu3 0.8s linear alternate infinite;
}
@-webkit-keyframes Loader__scale__HPuu3 {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
@keyframes Loader__scale__HPuu3 {
  100% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0;
  }
}
div.Loader__ldsLoader__32ymL li:nth-child(1) {
  z-index: 24;
}
div.Loader__ldsLoader__32ymL li:nth-child(2) {
  z-index: 23;
}
div.Loader__ldsLoader__32ymL li:nth-child(3) {
  z-index: 22;
}
div.Loader__ldsLoader__32ymL li:nth-child(4) {
  z-index: 21;
}
div.Loader__ldsLoader__32ymL li:nth-child(5) {
  z-index: 20;
}
div.Loader__ldsLoader__32ymL li:nth-child(6) {
  z-index: 19;
}
div.Loader__ldsLoader__32ymL li:nth-child(7) {
  z-index: 18;
}
div.Loader__ldsLoader__32ymL li:nth-child(8) {
  z-index: 17;
}
div.Loader__ldsLoader__32ymL li:nth-child(9) {
  z-index: 16;
}
div.Loader__ldsLoader__32ymL li:nth-child(10) {
  z-index: 15;
}
div.Loader__ldsLoader__32ymL li:nth-child(11) {
  z-index: 14;
}
div.Loader__ldsLoader__32ymL li:nth-child(12) {
  z-index: 13;
}
div.Loader__ldsLoader__32ymL li:nth-child(13) {
  z-index: 12;
}
div.Loader__ldsLoader__32ymL li:nth-child(14) {
  z-index: 11;
}
div.Loader__ldsLoader__32ymL li:nth-child(15) {
  z-index: 10;
}
div.Loader__ldsLoader__32ymL li:nth-child(16) {
  z-index: 9;
}
div.Loader__ldsLoader__32ymL li:nth-child(17) {
  z-index: 8;
}
div.Loader__ldsLoader__32ymL li:nth-child(18) {
  z-index: 7;
}
div.Loader__ldsLoader__32ymL li:nth-child(19) {
  z-index: 6;
}
div.Loader__ldsLoader__32ymL li:nth-child(20) {
  z-index: 5;
}
div.Loader__ldsLoader__32ymL li:nth-child(21) {
  z-index: 4;
}
div.Loader__ldsLoader__32ymL li:nth-child(22) {
  z-index: 3;
}
div.Loader__ldsLoader__32ymL li:nth-child(23) {
  z-index: 2;
}
div.Loader__ldsLoader__32ymL li:nth-child(24) {
  z-index: 1;
}
div.Loader__ldsLoader__32ymL li:nth-child(25) {
  z-index: 0;
}
div.Loader__ldsLoader__32ymL li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
div.Loader__ldsLoader__32ymL li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.Loader__ldsLoader__32ymL li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.Loader__ldsLoader__32ymL li:nth-child(24) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
div.Loader__ldsLoader__32ymL li:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
div.Loader__ldsLoader__32ymL li:nth-child(8) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.Loader__ldsLoader__32ymL li:nth-child(14) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.Loader__ldsLoader__32ymL li:nth-child(20) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
div.Loader__ldsLoader__32ymL li:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.Loader__ldsLoader__32ymL li:nth-child(9) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(15) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.Loader__ldsLoader__32ymL li:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.Loader__ldsLoader__32ymL li:nth-child(10) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.Loader__ldsLoader__32ymL li:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
div.Loader__ldsLoader__32ymL li:nth-child(6) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
div.Loader__ldsLoader__32ymL li:nth-child(11) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.Loader__ldsLoader__32ymL li:nth-child(16) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.Loader__ldsLoader__32ymL li:nth-child(21) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
div.Loader__ldsLoader__32ymL li:nth-child(12) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
div.Loader__ldsLoader__32ymL li:nth-child(17) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(22) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.Loader__ldsLoader__32ymL li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
div.Loader__ldsLoader__32ymL li:nth-child(18) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
div.Loader__ldsLoader__32ymL li:nth-child(23) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.Loader__ldsLoader__32ymL li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
div.Loader__ldsLoader__32ymL li:nth-child(24) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
div.Loader__ldsLoader__32ymL li:nth-child(25) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}


  
 div.SignUp__signupForm__1YoA7{
    height: 100vh;
    width: 100vw;
    margin: 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background: #f3f2f2;
  }
  
  div.SignUp__signupForm__1YoA7 h4 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    opacity: 0.85;
  }
  
  div.SignUp__signupForm__1YoA7 label {
    font-size: 12.5px;
    color: #000;
    opacity: 0.8;
    font-weight: 400;
  }
  
  div.SignUp__signupForm__1YoA7 form {
    padding: 40px 30px;
    background: #fefefe;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-bottom: 20px;
    width: 400px;
  }
  div.SignUp__signupForm__1YoA7 form h4 {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
  div.SignUp__signupForm__1YoA7 form h4 span {
    color: black;
    font-weight: 700;
  }
  div.SignUp__signupForm__1YoA7 form p {
    line-height: 155%;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    opacity: 0.65;
    font-weight: 400;
    max-width: 200px;
    margin-bottom: 40px;
  }
  
  div.SignUp__signupForm__1YoA7 a.SignUp__discrete__37ve6 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    border-bottom: solid 1px rgba(0, 0, 0, 0);
    padding-bottom: 4px;
    margin-left: auto;
    font-weight: 300;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 40px;
  }
  div.SignUp__signupForm__1YoA7 a.SignUp__discrete__37ve6:hover {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  
  div.SignUp__signupForm__1YoA7 button {
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 15px 40px;
    margin-top: 5px;
    background-color: #b08bf8;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    font-weight: 500;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.13);
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    outline: 0;
    cursor: pointer;
  }
  div.SignUp__signupForm__1YoA7 button:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 2px 6px -1px rgba(182, 157, 230, 0.65);
  }
  div.SignUp__signupForm__1YoA7 button:hover:active {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
  }
  
  div.SignUp__signupForm__1YoA7 input {
    font-size: 16px;
    padding: 20px 0px;
    height: 56px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 280px;
    box-sizing: border-box;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;
  }
  div.SignUp__signupForm__1YoA7 input:focus {
    border-bottom: solid 1px #b69de6;
    outline: 0;
    box-shadow: 0 2px 6px -8px rgba(182, 157, 230, 0.45);
  }
  
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j label {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-left: 44px;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input {
    width: calc(100% - 44px);
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j .SignUp__icon__34rcU {
    position: absolute;
    top: 0px;
    left: 0;
    height: 54px;
    width: 54px;
    font-size: 20px;
    color: rgb(65, 65, 65);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
  }
  
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input:not(:placeholder-shown) {
    padding: 28px 0px 12px 0px;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input:not(:placeholder-shown) + label {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0.7;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input:valid:not(:placeholder-shown) + div.SignUp__signupForm__1YoA7 label + div.SignUp__signupForm__1YoA7 .SignUp__icon__34rcU svg {
    opacity: 1;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input:valid:not(:placeholder-shown) + div.SignUp__signupForm__1YoA7 label + div.SignUp__signupForm__1YoA7 .SignUp__icon__34rcU svg path {
    fill: #b69de6;
  }
  div.SignUp__signupForm__1YoA7 .SignUp__floatingLabel__IUe_j input:not(:valid):not(:focus) + div.SignUp__signupForm__1YoA7 label + div.SignUp__signupForm__1YoA7 .SignUp__icon__34rcU {
    -webkit-animation-name: SignUp__shake-shake__3yII6;
            animation-name: SignUp__shake-shake__3yII6;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  
  @-webkit-keyframes SignUp__shake-shake__3yII6 {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
  @keyframes SignUp__shake-shake__3yII6 {
    0% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    20% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    40% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  div.SignUp__signupForm__1YoA7 .SignUp__session__2fWzJ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
  
  div.SignUp__signupForm__1YoA7 .SignUp__left__37WCz {
    width: 220px;
    height: auto;
    min-height: 100%;
    position: relative;
    background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  @media(max-width:630px){
    div.SignUp__signupForm__1YoA7 .SignUp__left__37WCz{
      display: none;
    }
    div.SignUp__signupForm__1YoA7 form{
      width: auto;
    }
    div.SignUp__signupForm__1YoA7 form p{
      margin-bottom: 3px;
    }
    div.SignUp__signupForm__1YoA7 button{
      margin-left: 20%;
    }
  }
  

#Nav__menuToggle__2u3ws a
{
  text-decoration: none;
  color: #232323;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;

}

#Nav__menuToggle__2u3ws a:hover
{
  color: tomato;
}

#Nav__menuToggle__2u3ws
{
  display: block;
  position: fixed;
  top: 50px;
  left: 50px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

#Nav__menuToggle__2u3ws input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#Nav__menuToggle__2u3ws span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  -webkit-transform-origin: 4px 0px;
  
          transform-origin: 4px 0px;
  
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#Nav__menuToggle__2u3ws span:first-child
{
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#Nav__menuToggle__2u3ws span:nth-last-child(2)
{
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#Nav__menuToggle__2u3ws input:checked ~ span
{
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#Nav__menuToggle__2u3ws input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#Nav__menuToggle__2u3ws input:checked ~ span:nth-last-child(2)
{
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#Nav__menu__1n2ZQ
{
  position: absolute;
  width: 300px;
  margin: -77px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  box-sizing: border-box;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#Nav__menu__1n2ZQ li
{
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#Nav__menuToggle__2u3ws input:checked ~ ul
{
  -webkit-transform: none;
          transform: none;
}
@media print {
  #Nav__menuToggle__2u3ws,#Nav__menu__1n2ZQ {
    display: none;
  }
}
.Resume__resumeList__2P4Fd {
    width: 200px;
    margin:10px;
    background: #FDFEFF;
    -webkit-transition: background .6s ease;
    transition: background .6s ease;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  }
  .Resume__resumeList__2P4Fd .Resume__topIcons__1dkQl i {
    color: #080911;
  }
  .Resume__resumeList__2P4Fd .Resume__topIcons__1dkQl i:nth-of-type(1) {
    float: left;
  }
  .Resume__resumeList__2P4Fd .Resume__topIcons__1dkQl i:nth-of-type(2) {
    float: right;
  }
  .Resume__resumeList__2P4Fd .Resume__topIcons__1dkQl i:nth-of-type(3) {
    float: right;
    padding-right: .8em;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- {
    margin-top: 2.2em;
    position: relative;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I-:after {
    width: 100%;
    height: 1px;
    content: ' ';
    display: block;
    margin-top: 1.3em;
    background: #E9EFF6;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__check__HKvos {
    position: absolute;
    right: 5em;
    bottom: 12.7em;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__check__HKvos i {
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    background: -webkit-linear-gradient(top left, #C90A6D, #FF48A0);
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__thumbnail__1R-BY {
    width: 124px;
    height: 124px;
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    border-radius: 100%;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__name__3QKLt {
    color: #2D354A;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__title__3xiZd {
    color: #7C8097;
    font-size: .75em;
    font-weight: 300;
    text-align: center;
    padding-top: .5em;
    padding-bottom: .7em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__description__1hIte {
    color: #080911;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1.3em;
  }
  .Resume__resumeList__2P4Fd .Resume__profile__3F5I- .Resume__btn__21qFM {
    color: #fff;
    width: 130px;
    height: 42px;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    border-radius: 70px;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
    background: -webkit-linear-gradient(top left, #C90A6D, #FF48A0);
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 {
    display: -webkit-flex;
    display: flex;
    margin-top: 1.2em;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq button {
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 28px;
    line-height: 60px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq:nth-of-type(1) button {
    background: -webkit-linear-gradient(top left, #C90A6D, #FF48A0);
    background: linear-gradient(to bottom right, #C90A6D, #FF48A0);
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq:nth-of-type(2) button{
    background: -webkit-linear-gradient(top left, #5E5AEC, #3F9EFC);
    background: linear-gradient(to bottom right, #5E5AEC, #3F9EFC);
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq:nth-of-type(3) button {
    background: -webkit-linear-gradient(top left, #6452E9, #639FF9);
    background: linear-gradient(to bottom right, #6452E9, #639FF9);
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq h4 {
    color: #080911;
    font-size: 1em;
    margin-top: 1.3em;
    margin-bottom: .2em;
  }
  .Resume__resumeList__2P4Fd .Resume__socialIcons__1QMk4 .Resume__icon__1nimq p {
    color: #666B7D;
    font-size: 12px;
  }
  
.AllResumes__allResumes__1_NMF{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit,minmax(255px,0fr));
}
.Welcome__welcomeContainer__2aC9Y{
    padding: 60px 20px 10px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;

}
div.Template__templateEdit__1SDlK{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
div.Template__templateEdit__1SDlK div.Template__leftHalf__2IhwI{
    width: 40vw;
    padding:40px 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
}
div.Template__templateEdit__1SDlK div.Template__leftHalf__2IhwI .Template__sectionList__2xA2l{
    width: 80%;
    text-align: center;
}
div.Template__templateEdit__1SDlK div.Template__rightHalf__2wgDn{
    width: 58vw;
    height: 100vh;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.194);
    padding: 10px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.Template__sticky__3gNjx{
    position: -webkit-sticky;
    position: sticky;
    top: -50px;
    text-align: center;
    left:0;
    right: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
}
@media(max-width:700px){
    div.Template__templateEdit__1SDlK{
        -webkit-flex-flow: column;
                flex-flow: column;
    }
    #Template__templateView__2HW0J{
        margin-top: 80px;
    }
    div.Template__templateEdit__1SDlK div.Template__leftHalf__2IhwI{
        width: 100vw;
        height: auto;
        padding-left:0;
        padding-right:0;

    }
    div.Template__templateEdit__1SDlK div.Template__rightHalf__2wgDn{
        padding-left:0;
        height: auto;
        padding-right:0;
        width: 100vw;
    }
}
#Template__templateView__2HW0J{
    padding: 10px;
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    -moz-page-break-inside: avoid; /* Firefox */
    page-break-inside: avoid;
    break-inside: avoid; /* IE 10+ */
}
@media print {
    @page {
        margin: 2.2cm 2.2cm 1.8cm;
        
      }
      
    button.Template__printBtn__39hO9{
        display: none;
    }
}
div.InputToTemplate__templateInput__28r_o label{
    display: block;
    font-weight: 600;
}
div.InputToTemplate__templateInput__28r_o input{
    font-family: 'M PLUS Rounded 1c', sans-serif;
  margin:10px;
  box-sizing:border-box;
  border:1px solid rgba(0,0,0,0.05) ;
  padding:10px;
  width:100%;
  border-radius:4px;
  font-size:15px;
  box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.08);
  -webkit-transition: box-shadow 0.4s ease-in-out;
  transition: box-shadow 0.4s ease-in-out;
}
div.InputToTemplate__templateInput__28r_o input:focus{
    box-shadow: 0px 3px 20px 6px rgba(0,0,0,0.12);
}
div.Style__template2Body__3uZ3s{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
}
div.Style__template2__3Uifl {
    font-family: "Adobe Caslon Pro", "Minion Pro", serif;
    font-size: 12pt;
    width:100%;
    max-width: 800px;

}

div.Style__template2__3Uifl header {
    font-family: "Trajan Pro", serif;
    padding-bottom: 10px;
}

div.Style__template2__3Uifl header h1 {
    font-size: 20pt;
    letter-spacing: 2pt;
    border-bottom: 1px solid black;
    margin-bottom: 4px;
}

div.Style__template2__3Uifl header span {
    font-size: 10pt;
    float: right;
}

div.Style__template2__3Uifl section h2 {
    font-family: "Trajan Pro", serif;
    font-size: 14pt;
}
div.Style__template2__3Uifl section{
    margin: 20px 0px;
    box-sizing: border-box;
}
div.Style__template2__3Uifl section p {
    padding-left: 40px;
    box-sizing: border-box;
}

div.Style__template2__3Uifl section.Style__coverletter__3Jrm- {
    margin-top: 40px;
}

div.Style__template2__3Uifl section.Style__coverletter__3Jrm- p {
    margin-left: 0px;
}

div.Style__template2__3Uifl section ul {
    list-style-type: circle;
}

div.Style__template2__3Uifl .Style__jobtable__3jLan {
    display: table;
    width: 100%;
    border-bottom: 1px solid black;
    padding-left: 20px;
    box-sizing: border-box;

}

div.Style__template2__3Uifl .Style__edtable__Bbben {
    display: table;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 15px;
    box-sizing: border-box;

}

div.Style__template2__3Uifl .Style__skillstable__1yhHN {
    display: table;
    width: 100%;
}

div.Style__template2__3Uifl .Style__table__300XQ {
    display: table;
    padding-left: 20px;
    box-sizing: border-box;

}

div.Style__template2__3Uifl .Style__tablerow__2yVc6 {
    display: table-row;
}

div.Style__template2__3Uifl .Style__jobtitle__3QYjh {
    display: table-cell;
    font-style: italic;
}

div.Style__template2__3Uifl .Style__right__1XuXr {
    display: table-cell;
    text-align: right;
}

div.Style__template2__3Uifl .Style__cell__2_dYf {
    display: table-cell;
}

div.Style__template2__3Uifl .Style__onlinecell__3LUFm {
    font-style: italic;
    padding-right: 10px;
    box-sizing: border-box;

}

div.Style__template2__3Uifl .Style__urlcell__1gXEG {
    display: table-cell;
    letter-spacing: 1px;
}

div.Style__template2__3Uifl .Style__pagebreak__JEbBM {
    page-break-before: always;
}

html {
            font-size: 62.5%;
            box-sizing: border-box
        }

        .Style__template3Body__10KeB *,
        .Style__template3Body__10KeB *:before,
        .Style__template3Body__10KeB *:after {
            box-sizing: inherit
        }

        .Style__template3Body__10KeB {
            margin: 0;
            background-color: #fff
        }

        .Style__template3Body__10KeB img {
            max-width: 100%;
            height: auto
        }

        .Style__template3Body__10KeB .Style__uCf__QhWht:before,
        .Style__template3Body__10KeB .Style__uCf__QhWht:after {
            content: " ";
            display: table
        }

        .Style__template3Body__10KeB .Style__uCf__QhWht:after {
            clear: both
        }

        .Style__template3Body__10KeB .Style__uHug__2AQpU {
            margin-top: 0 !important
        }

        .Style__template3Body__10KeB .Style__uPullRight__1tO-J {
            float: right
        }

        .Style__template3Body__10KeB .Style__uKeyline__MsiGz {
            position: relative
        }

         .Style__template3Body__10KeB .Style__uKeyline__MsiGz:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: 1px solid #ddd
        }

        .Style__template3Body__10KeB .Style__uLink__31_Up {
            text-decoration: none;
            position: relative;
            display: inline-block
        }

        .Style__template3Body__10KeB .Style__uLink__31_Up:before {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: .2em;
            width: 100%;
            height: 2px;
            background-color: #96ddcf
        }

        .Style__template3Body__10KeB .Style__uLink__31_Up:visited:before {
            background-color: #ddd
        }

        .Style__template3Body__10KeB .Style__uLink__31_Up:hover:before {
            background-color: #fa8072
        }

        .Style__template3Body__10KeB .Style__uShadow__3ExYW {
            text-shadow: 4px 4px 0 #6fd1bd;
            -webkit-transition: text-shadow .2s ease-in-out;
            transition: text-shadow .2s ease-in-out
        }

        .Style__template3Body__10KeB .Style__uShadow__3ExYW:hover {
            text-shadow: -4px -4px 0 salmon
        }

        @media (max-width:767px) {
            .Style__template3Body__10KeB    .Style__uHidden__3BdNj {
                display: none !important
            }
        }

        @media (max-width:543px) {
            .Style__template3Body__10KeB   .Style__uHidden__3BdNj {
                display: none !important
            }
        }

        .Style__template3Body__10KeB {
            color: #333;
            font-size: 1.6rem;
            font-family: Lato, Helvetica, Arial, sans-serif;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: .008em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        .Style__template3Body__10KeB h1,
        .Style__template3Body__10KeB h2,
        .Style__template3Body__10KeB h3,
        .Style__template3Body__10KeB h4,
        .Style__template3Body__10KeB h5,
        .Style__template3Body__10KeB h6 {
            color: #111;
            line-height: 1.2;
            font-weight: 900
        }

        .Style__template3Body__10KeB h1,
        .Style__template3Body__10KeB h2,
        .Style__template3Body__10KeB h3,
        .Style__template3Body__10KeB h4,
        .Style__template3Body__10KeB h5,
        .Style__template3Body__10KeB h6,
        .Style__template3Body__10KeB ul,
        .Style__template3Body__10KeB p {
            margin-top: 1.6rem;
            margin-bottom: 0
        }

        .Style__template3Body__10KeB h3,
        .Style__template3Body__10KeB .Style__h3__2uR93 {
            font-size: 2rem;
            margin-top: 3.2rem
        }

        .Style__template3Body__10KeB a {
            color: #333
        }

        .Style__template3Body__10KeB ul {
            padding-left: 1.8rem
        }

        .Style__template3Body__10KeB .Style__Title__Xhgoe {
            font-size: 50px;
            font-size: 8vw;
            font-family: Lato, 'Open Sans', Helvetica, Arial;
            font-weight: 900;
            line-height: 1.1;
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
            display: inline-block
        }

        @media (max-width:525px) {
            .Style__template3Body__10KeB  .Style__Title__Xhgoe {
                font-size: 42px
            }
        }

        @media (min-width:937.5px) {
            .Style__template3Body__10KeB .Style__Title__Xhgoe {
                font-size: 75px
            }
        }

        .Style__template3Body__10KeB .Style__TitleSub__2h8_C {
            font-size: .34em;
            letter-spacing: .16em;
            font-weight: 400;
            text-transform: none;
            display: block;
            margin-top: 1.2rem;
            padding-top: 1.6rem;
            border-top: .45em solid
        }

        .Style__template3Body__10KeB .Style__lWrapper__10zKk {
            width: 80%;
            max-width: 90rem;
            margin-left: auto;
            margin-right: auto
        }

        @media (max-width:767px) {
            .Style__template3Body__10KeB  .Style__lWrapper--reset__2lhkn {
                width: 100%
            }
        }

        .Style__template3Body__10KeB .Style__lHeader__1X2Wv {
            margin-top: 6.4rem;
            margin-bottom: 3.2rem
        }

        .Style__template3Body__10KeB .Style__lHeaderCol__2G0is {
            margin-top: 3.2rem;
            margin-bottom: 3.2rem
        }

        @media (min-width:544px) {
            .Style__template3Body__10KeB  .Style__lHeader__1X2Wv {
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                        justify-content: space-between;
                -webkit-align-items: flex-end;
                        align-items: flex-end
            }

            .Style__template3Body__10KeB  .Style__lHeaderCol__2G0is:last-child:not(:first-child) {
                text-align: right
            }
        }

        .Style__template3Body__10KeB .Style__lSection__rYoXG {
            margin-top: 6.4rem
        }

        .Style__template3Body__10KeB .Style__lSection__rYoXG+.Style__template3Body__10KeB .Style__lSection__rYoXG {
            position: relative;
            border-top: 1px solid #ddd
        }

        .Style__template3Body__10KeB .Style__lSection__rYoXG+.Style__template3Body__10KeB .Style__lSection__rYoXG:before {
            content: "";
            font-size: 50px;
            font-size: 8vw;
            position: absolute;
            top: -.1rem;
            border-top: .153em solid;
            width: 4rem
        }

        @media (max-width:525px) {
            .Style__template3Body__10KeB .Style__lSection__rYoXG+.Style__template3Body__10KeB .Style__lSection__rYoXG:before {
                font-size: 42px
            }
        }

        @media (min-width:937.5px) {
            .Style__template3Body__10KeB .Style__lSection__rYoXG+.Style__template3Body__10KeB .Style__lSection__rYoXG:before {
                font-size: 75px
            }
        }

        .Style__template3Body__10KeB .Style__lSectionTitle__28gsL,
        .Style__template3Body__10KeB  .Style__lSectionContent__3I5xL {
            margin-top: 3.2rem
        }

        .Style__template3Body__10KeB .Style__lSectionContent__3I5xL>:first-child,
        .Style__template3Body__10KeB .Style__lSectionContent__3I5xL>:first-child>h3 {
            margin-top: 0
        }

        .Style__template3Body__10KeB .Style__lSectionContent__3I5xL>p:first-child,
        .Style__template3Body__10KeB .Style__lSectionContent__3I5xL>ul:first-child {
            margin-top: .3rem
        }

        .Style__template3Body__10KeB .Style__lSectionGroup__1DV5o {
            display: inline
        }

        @media (min-width:768px) {
            .Style__template3Body__10KeB  .Style__lSection__rYoXG {
                display: -webkit-flex;
                display: flex
            }

            .Style__template3Body__10KeB .Style__lSection__rYoXG:before {
                display: none
            }

            .Style__template3Body__10KeB .Style__lSectionTitle__28gsL {
                -webkit-flex: 0 0 24%;
                        flex: 0 0 24%
            }
        }

        .Style__template3Body__10KeB .Style__lFooter__2uzeF {
            font-size: 1.4rem;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            margin: 12.8rem -.8rem 3.2rem;
            padding-top: 3.2rem
        }

        .Style__template3Body__10KeB .Style__lFooter__2uzeF a {
            text-decoration: none;
            display: inline-block
        }

        .Style__template3Body__10KeB .Style__lFooter__2uzeF a:not(:last-child) {
            margin-right: 1.6rem
        }

        .Style__template3Body__10KeB .Style__lFooter__2uzeF a:hover {
            color: #111
        }

        .Style__template3Body__10KeB .Style__lFooterCol__XAcac {
            padding-left: .8rem;
            padding-right: .8rem
        }

       

        

        .Style__template3Body__10KeB .Style__Tag__1iPaG {
            color: #111;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, .4);
            font-weight: 700;
            line-height: 1;
            position: relative;
            display: inline-block;
            padding-left: .8rem;
            padding-right: .8rem
        }

        .Style__template3Body__10KeB .Style__Tag__1iPaG:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            height: 1em;
            bottom: 0;
            margin: auto;
            border-radius: .2rem;
            background-color: rgba(111, 209, 189, .5);
            -webkit-transition: all .8s .4s ease-in-out;
            transition: all .8s .4s ease-in-out
        }

        .Style__template3Body__10KeB .Style__Tag__1iPaG:hover:before {
            -webkit-transform: scale(1.05, 2.4);
                    transform: scale(1.05, 2.4);
            background-color: #6fd1bd;
            -webkit-transition-delay: 0s;
                    transition-delay: 0s;
            -webkit-transition-duration: .2s;
                    transition-duration: .2s
        }

         @media print {
            .Style__template3Body__10KeB  {
                font-size:15px;
            }

            .Style__template3Body__10KeB .Style__lWrapper__10zKk {
                width: 100%;
                max-width: none
            }

           
            .Style__template3Body__10KeB .Style__uHidden-print__38ciX {
                display: none
            }

            .Style__template3Body__10KeB  .Style__lHeader__1X2Wv,
            .Style__template3Body__10KeB .Style__lHeaderCol__2G0is {
                margin-top: 0
            }

            .Style__template3Body__10KeB  .Style__lFooter__2uzeF,
            .Style__template3Body__10KeB  .Style__lSection__rYoXG {
                margin-top: 3.2rem
            }

            .Style__template3Body__10KeB  h3,
          .Style__template3Body__10KeB  .Style__h3__2uR93,
          .Style__template3Body__10KeB  .Style__lSectionTitle__28gsL,
          .Style__template3Body__10KeB  .Style__lSectionContent__3I5xL {
                margin-top: 1.8rem
            }

            .Style__template3Body__10KeB .Style__Title__Xhgoe {
                font-size: 4.5rem
            }

            .Style__template3Body__10KeB .Style__lHeader__1X2Wv {
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                        justify-content: space-between;
                -webkit-align-items: flex-end;
                        align-items: flex-end
            }

            .Style__template3Body__10KeB  .Style__lHeaderCol__2G0is:last-child:not(:first-child) {
                text-align: right
            }

            .Style__template3Body__10KeB  .Style__lSection__rYoXG {
                position: relative;
                padding-top: 5px;
                border-top: 1px solid #ddd
            }

            .Style__template3Body__10KeB .Style__lSection__rYoXG:before {
                display: none
            }

            .Style__template3Body__10KeB .Style__lSection__rYoXG:after {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                border-top: 5px solid;
                width: 4rem
            }

            .Style__template3Body__10KeB  .Style__lSectionGroup__1DV5o {
                display: block;
                page-break-inside: avoid
            }

            .Style__template3Body__10KeB .Style__Tag__1iPaG {
                font-weight: 900;
                display: inline;
                padding-left: 0;
                padding-right: 0
            }

           .Style__template3Body__10KeB *,
           .Style__template3Body__10KeB *:before,
           .Style__template3Body__10KeB *:after,
           .Style__template3Body__10KeB p:first-letter,
           .Style__template3Body__10KeB div:first-letter,
           .Style__template3Body__10KeB blockquote:first-letter,
           .Style__template3Body__10KeB li:first-letter,
           .Style__template3Body__10KeB p:first-line,
           .Style__template3Body__10KeB div:first-line,
           .Style__template3Body__10KeB blockquote:first-line,
           .Style__template3Body__10KeB li:first-line {
                background: transparent !important;
                color: #000 !important;
                box-shadow: none !important;
                text-shadow: none !important
            }

          .Style__template3Body__10KeB  a,
          .Style__template3Body__10KeB  a:visited {
                text-decoration: underline
            }

           .Style__template3Body__10KeB p,
           .Style__template3Body__10KeB h2,
           .Style__template3Body__10KeB h3,
           .Style__template3Body__10KeB li {
                orphans: 3;
                widows: 3
            }

           .Style__template3Body__10KeB h2,
           .Style__template3Body__10KeB h3 {
                page-break-after: avoid
            }

           .Style__template3Body__10KeB .Style__Contact__eOdGc:after {
                content: "(206) 960-0961";
                padding-left: 1.6rem
            }
        }
        @page {
            margin: 2.2cm 2.2cm 1.8cm
        } 
.Style__sep2__3QzvP{
     overflow: scroll;
 }
 .Style__row__FcBEO{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
 }
 div.Style__template4BodyCont__n_xcq{
     max-width: 900px;
    
 }
 div.Style__template4Body__3EX92{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;

    -webkit-align-items: center;

            align-items: center;
 }
 
    /* div.template4BodyCont{
        
        font-size: 20px;
    } */
    @media print {

       
        th {
            text-align: inherit !important;
          }
          .Style__table__jY_0t {
            width: 100% !important;
            margin-bottom: 1rem !important;
            color: #212529 !important;
          }
          
          .Style__table__jY_0t th,
          .Style__table__jY_0t td {
            padding: 0.75rem !important;
            vertical-align: top !important;
            border-top: 1px solid #dee2e6 !important;
          }
          
          .Style__table__jY_0t thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6 !important;
          }
          
          .Style__table__jY_0t tbody + tbody {
            border-top: 2px solid #dee2e6 !important;
          }
          .Style__table-bordered__2uJpx {
            border: 1px solid #dee2e6 !important;
          }
          
          .Style__table-bordered__2uJpx th,
          .Style__table-bordered__2uJpx td {
            border: 1px solid #dee2e6 !important;
          }
          
          .Style__table-bordered__2uJpx thead th,
          .Style__table-bordered__2uJpx thead td {
            border-bottom-width: 2px !important;
          }
        div.Style__template4Body__3EX92  .Style__table-striped__2eCIp tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05) !important;
          }
          div.Style__template4Body__3EX92   .Style__table-dark__3R2Ws.Style__table-striped__2eCIp tbody tr:nth-of-type(odd) {
            background-color: rgba(255, 255, 255, 0.05) !important;
          }
        div.Style__template4Body__3EX92{
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: column;
                    flex-flow: column;
            -webkit-justify-content: center;
                    justify-content: center;
            font-size: 15px !important;
            -webkit-align-items: center;
                    align-items: center;
            margin: 2mm 0mm 2mm 15mm;
         }
        div.Style__template4BodyCont__n_xcq{
            max-width: unset;
           
        }
        div.Style__template4Body__3EX92 h2{
            font-weight:600; 
            margin: 6px 0px 0px 0px;
        }
        
        div.Style__template4Body__3EX92,div.Style__template4Body__3EX92 h1,div.Style__template4Body__3EX92 h2,div.Style__template4Body__3EX92 h3,div.Style__template4Body__3EX92 ol,div.Style__template4Body__3EX92 ul,div.Style__template4Body__3EX92 div,div.Style__template4Body__3EX92 span,div.Style__template4Body__3EX92 p {
            display: block !important;
            /* width: auto !important; */
            /* float: none !important; */
            /* position: static !important; */
            overflow: visible !important;
        }
        
      }
 

